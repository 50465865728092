/* global Turbo */

const DEFAULT_CONFIRM_TEXT = 'Confirm'
const DEFAULT_CANCEL_TEXT = 'Cancel'

Turbo.setConfirmMethod((message, element, submitter) => {
  const confirmText = typeof submitter !== 'undefined' ? submitter.dataset.turboConfirmPositiveText : DEFAULT_CONFIRM_TEXT
  const cancelText = typeof submitter !== 'undefined' ? submitter.dataset.turboConfirmNegativeText : DEFAULT_CANCEL_TEXT
  const title = typeof submitter !== 'undefined' ? submitter.dataset.turboConfirmTitle : null

  return confirm(message, confirmText, cancelText, title)
})

export function confirm (message = 'Are you sure?', confirmText = DEFAULT_CONFIRM_TEXT, cancelText = DEFAULT_CANCEL_TEXT, title = null) {
  const titleHTML = title ? `<p class="text-lg font-semibold mb-4">${title}</p>` : ''

  const dialogHTML = `
    <dialog id="turbo-confirm" class="p-4 max-w-prose bg-transparent backdrop:backdrop-brightness-50">
      <form method="dialog" class="px-12 py-8 border border-inherit bg-white text-gray-900 rounded-md shadow-md dark:bg-bunker-850 dark:text-bunker-200">
        ${titleHTML}
        <p>${message}</p>
        <fieldset class="mt-8 w-full flex gap-x-8 justify-end">
          <button value="cancel" class="button">${cancelText}</button>
          <button value="confirm" class="button-primary">${confirmText}</button>
        </fieldset>
      </form>
    </dialog>
  `
  document.body.insertAdjacentHTML('beforeend', dialogHTML)

  const dialog = document.getElementById('turbo-confirm')

  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener(
      'close',
      () => {
        if (dialog.returnValue === 'confirm') {
          resolve(true)
        }
        dialog.remove()
      },
      { once: true }
    )
  })
}
